import React, { lazy } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
const ProcessedRoutes = lazy(() => import("./ProcessedRoutes"));
const Auth = lazy(() => import("../Components/Screen/Auth"));

const Router = () => {
    const styles = useStyles();
    return (
        <Box className={styles?.container} id={"routeBox"}>
            <AuthenticatedTemplate>
                <ProcessedRoutes />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Auth />
            </UnauthenticatedTemplate>
        </Box>
    );
};

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
    },
});

export default Router;
