import React from "react";
import "./App.css";
import Routes from "./Nodes/Routes";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

function App() {
    const configuration: Configuration = {
        auth: {
            clientId: "867b7713-d096-4c6e-b5f5-4f9d21d6e7bf",
            authority: `https://login.microsoftonline.com/${"358b0b50-5bbc-4f43-9ae9-84e5db58b77b"}`,
            redirectUri: "/",
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true,
        },
    };
    const pca = new PublicClientApplication(configuration);

    return (
        <MsalProvider instance={pca}>
            <Routes />
        </MsalProvider>
    );
}

export default App;
